import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import {
	IOSEventType,
	IOSMessageHandlerName,
	isLaunchedAsIOSApp,
	postMessageToIOSWebView,
	subscribeForIOSEvent,
} from 'src/lib/ios'
import { useUser } from 'src/lib/react-hooks/generic-hooks'
import { loadData } from 'src/lib/requests'
import { CountryLanguagesActions } from 'src/redux/actions'
import { HTTPMethod } from 'src/travelsuit'

export const AuthenticatedAppStateFetch: React.FC = () => {
	const user = useUser()
	const dispatch = useDispatch()

	const userId = user?.id
	const isUserLoaded = !!user

	useEffect(() => {
		if (!isUserLoaded) {
			return
		}

		dispatch(CountryLanguagesActions.getContryLanguages())

		if (!isLaunchedAsIOSApp()) {
			return
		}

		postMessageToIOSWebView(IOSMessageHandlerName.PushPermissionRequest, undefined)

		let timeoutId: number | undefined = undefined

		const unsubscribe = subscribeForIOSEvent(IOSEventType.PushToken, ({ detail }) => {
			if (detail) {
				loadData({
					resourcePath: `users/${userId}/device_token`,
					method: HTTPMethod.POST,
					data: { device_token: detail },
				})
			} else {
				timeoutId = window.setTimeout(
					() => postMessageToIOSWebView(IOSMessageHandlerName.PushToken, undefined),
					60 * 1000,
				)
			}
		})

		postMessageToIOSWebView(IOSMessageHandlerName.PushToken, undefined)

		return () => {
			unsubscribe()
			if (timeoutId) {
				clearTimeout(timeoutId)
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, isUserLoaded])

	return null
}
