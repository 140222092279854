import { AxiosError, ResponseType } from 'axios'

import { Itinerary, VoidCallback } from 'src/travelsuit'

export interface IAction<Payload = any, RequestPayload = Payload, CallbackResponse = any> {
	type: string
	payload?: Payload
	requestPayload?: RequestPayload
	requestParams?: any
	responseType?: ResponseType
	callback?: VoidCallback<CallbackResponse>
	errCallback?: VoidCallback<AxiosError>
	swapCallbackAndSuccessAction?: boolean
	body?: any
	params?: any
	polling?: { jobId: string }
}

export type IActionNoPayload<F = any> = IAction<never, never, F>

export type RequestTypeMapping = Record<
	'ORIGINAL' | 'REQUEST' | 'UPDATE' | 'SUCCESS' | 'FAILED' | 'REQUEST_JOB' | 'REQUEST_JOB_RESULT',
	string
>

export function RequestTypeAction(name: string, prefix?: string): RequestTypeMapping {
	prefix = prefix ? prefix + '/' : ''
	const original = `${prefix}${name}`
	return {
		ORIGINAL: original,
		REQUEST: `${original}_REQUEST`,
		UPDATE: `${original}_UPDATE`,
		SUCCESS: `${original}_SUCCESS`,
		FAILED: `${original}_FAILED`,
		REQUEST_JOB: `${original}_REQUEST_JOB`,
		REQUEST_JOB_RESULT: `${original}_REQUEST_JOB_RESULT`,
	}
}
export enum PollingStatus {
	Sent = 'sent',
	InProgress = 'in progress',
	Success = 'success',
	Expired = 'expired',
	Failed = 'failed',
	Completed = 'completed',
}

export function isRequestTypeAction(object: unknown): object is RequestTypeMapping {
	return Object.getOwnPropertyNames(object).includes('ORIGINAL')
}

export function RequestTypeActionMap<T extends string>(keys: T[], prefix?: string): Record<T, RequestTypeMapping> {
	return keys.reduce(
		(all, k) => Object.assign(all, { [k]: RequestTypeAction(k, prefix) }),
		{} as Record<T, RequestTypeMapping>,
	)
}

export function ActionMap<T extends string>(keys: T[], prefix?: string): Record<T, string> {
	return keys.reduce(
		(all, k) => Object.assign(all, { [k]: [prefix, k].filter(Boolean).join('/') }),
		{} as Record<T, string>,
	)
}

export function removeSeatSelectionFromStore(itinerary: Itinerary, seatKeysForDeleting: string[]) {
	itinerary.flights_bookings.forEach((flightBooking) => {
		flightBooking.flights.forEach((flight) => {
			flight.segments.forEach((segment) => {
				segment.seat_selection = segment.seat_selection.filter((seat) => {
					return !seatKeysForDeleting.some(
						(keyForDeleting: string) => keyForDeleting === seat.seatKey || keyForDeleting === seat.seat_key,
					)
				})
			})
		})
	})

	return itinerary
}
