import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { StopIconMap } from 'src/atoms/TripStops/TripStops'
import { TripProductType } from 'src/travelsuit'
import { Fee, VirtualPaymentAutomationFee } from 'src/travelsuit/fees.types'

import { FeeDetail } from './FeeDetail'
import { TripFeeCardBase } from './TripFeeCardBase'

interface VirtualPaymentAutomationFeeCardProps {
	fee: Fee<VirtualPaymentAutomationFee>
	onFeesReload: () => void
}

const TripProductTypeLabels: Record<TripProductType, (t: TFunction) => string> = {
	[TripProductType.Flight]: (t) => t('Flight'),
	[TripProductType.Car]: (t) => t('Car'),
	[TripProductType.Hotel]: (t) => t('Hotel'),
	[TripProductType.Rails]: (t) => t('Train'),
}

export function VirtualPaymentAutomationFeeCard({ fee, onFeesReload }: VirtualPaymentAutomationFeeCardProps) {
	const { t } = useTranslation()

	const vpaFee = fee[fee.service_type]
	const productType = vpaFee.product_type

	return (
		<TripFeeCardBase
			fee={fee}
			onFeesReload={onFeesReload}
			primaryInfo={[vpaFee.bs_info.service_name, vpaFee.bs_info.service_dates].join(', ')}
			getFeeDetailsContent={(content) => (
				<>
					{content}
					<FeeDetail
						iconSrc={StopIconMap[productType]}
						e2e="ProductType"
						content={TripProductTypeLabels[productType](t)}
					/>
				</>
			)}
		/>
	)
}
