import { z } from 'zod'

import { ActiveUserFeeDetailsZ, FeeZ } from 'src/types/usageDetails'

import { getGGClient, RequestOverrides } from './GetGoingClient'

export async function getActiveUserFeeDetails({ feeId }: { feeId: number }, overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `companies/user_fee_usage_details/${feeId}`, {
			responseSchema: ActiveUserFeeDetailsZ,
			...overrides,
		})
		.then((r) => r.data)
}

export async function getRelatedFees({ feeId }: { feeId: number }, overrides?: RequestOverrides) {
	return getGGClient()
		.request('GET', `fee/${feeId}/related_fees`, {
			responseSchema: z.array(FeeZ),
			...overrides,
		})
		.then((r) => r.data)
}
