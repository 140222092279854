import React from 'react'

import { Font } from 'src/atoms/GenericComponents'
import { Stack } from 'src/atoms/System'
import { formatPrice } from 'src/lib/utils'
import { Currency } from 'src/types/common'

interface FeePaymentRow {
	label: string
	price: number
	currency: Currency
	boldPrice?: boolean
}

export function FeePaymentRow({ label, price, currency, boldPrice }: FeePaymentRow) {
	return (
		<Stack gap={1} flexDirection="row" justifyContent="space-between" data-test="FeesRows">
			<span>{label}</span>
			<Font weight={boldPrice ? 'bold' : undefined}>{formatPrice(price, currency)}</Font>
		</Stack>
	)
}
