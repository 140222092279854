import { z } from 'zod'

import {
	CurrencyZ,
	ISOCountry2Z,
	ISODateStringZ,
	OriginalAmountZ,
	PricesMetadataZ,
	StatsBucketsZ,
} from 'src/types/common'
import { BookingOptionUTAZ } from 'src/types/flights'
import {
	FlightAmenityZ,
	FlightBaggageAllowanceZ,
	FlightCarrierZ,
	FlightPenaltiesZ,
	FlightPolicyDeviationZ,
} from 'src/types/flights/search'
import { UserIdZ, UserMiniZ } from 'src/types/user'

import { getJobResultZ } from '../job'
import { SearchIdZ } from '../search'
import { BookingSegmentIdZ, BSTTIdZ } from '../trip'
import {
	AirlineZ,
	FlightBookingSegmentResIdZ,
	FlightCabinClassZ,
	FlightIdentifierZ,
	FlightProviderZ,
	FlightSegmentIdZ,
	FlightSliceIdZ,
	IATACodeZ,
} from './common'

export const FlightPriceChangeZ = z.strictObject({
	total_price: z.number(),
	total_tax: z.number(),
	old_price: z.number(),
	old_tax: z.number(),
})

export type FlightPriceChange = z.infer<typeof FlightPriceChangeZ>

/**
 * Flight booking segment to traveler
 */
export const FlightBSTTZ = z.strictObject({
	actual_original_total: z.null(),
	actual_total: z.null(),
	booking_errors: z.array(z.unknown()),
	booking_segment_id: BookingSegmentIdZ,
	cancellation_errors: z.null(),
	cancellation_status: z.null(),
	cancellation_status_dt: z.null(),
	cloned_booking_errors: z.array(z.unknown()),
	currency: CurrencyZ,
	has_unused_tickets: z.null(),
	id: BSTTIdZ,
	original_currency: z.string(),
	original_total: z.null(),
	payment_method: z.null(),
	payment_method_id: z.null(),
	payment_status: z.null(),
	pnr: z.null(),
	status: z.string(),
	total_price: z.number(),
	total_tax: z.number(),
	user_id: UserIdZ,
	vendor_booking_id: z.null(),
	vendor_reservation_status: z.null(),
	vendor_verification_url: z.null(),
	within_void_window: z.boolean(),
})

export type FlightBSTT = z.infer<typeof FlightBSTTZ>

export const BookingAirportZ = z.strictObject({
	city_code: IATACodeZ,
	code: IATACodeZ,
	country_iso: ISOCountry2Z,
	id: z.number(),
	latitude: z.number(),
	longitude: z.number(),
	name: z.string(),
})

export type BookingAirport = z.infer<typeof BookingAirportZ>

export const FlightBookingSliceSegmentZ = z.strictObject({
	aircraft_type: z.string(),
	amenities: z.array(FlightAmenityZ).nullable(),
	arrival: z.strictObject({
		airport: BookingAirportZ,
		datetime: ISODateStringZ,
	}),
	arrival_airport: BookingAirportZ,
	arrival_airport_id: z.number(),
	arrival_terminal: z.string(),
	arrival_time: z.string(),
	baggage_allowance: FlightBaggageAllowanceZ.nullable(),
	baggage_description: z.null(),
	baggage_weight: z.null(),
	bags_recheck_required: z.boolean(),
	cabin: FlightCabinClassZ,
	cabin_commercial_name: z.string(),
	carrier: FlightCarrierZ,
	departure: z.strictObject({
		airport: BookingAirportZ,
		datetime: ISODateStringZ,
	}),
	departure_airport: BookingAirportZ,
	departure_airport_id: z.number(),
	departure_terminal: z.string(),
	departure_time: z.string(),
	discounts: z.array(z.unknown()),
	emissions: z.number().nullable(),
	fare_rules: z.unknown(),
	flight_number: z.string(),
	/**
	 * Database ID of the flight segment in the booking segment
	 */
	id: FlightSegmentIdZ.optional(),
	marketing_airline: AirlineZ,
	marketing_airline_id: z.number(),
	number_of_bags: z.number(),
	number_of_paid_bags: z.number(),
	operating_airline: AirlineZ,
	operating_airline_id: z.number(),
	operating_carrier: FlightCarrierZ,
	plane_type: z.null(),
	seat_selection: z.unknown(),
	segment_to_users: z.array(z.unknown()),
	stop_airports: z.null(),
	vendor_specific_info: z.null(),
})

export type FlightBookingSliceSegment = z.infer<typeof FlightBookingSliceSegmentZ>

export const FlightBookingSliceZ = z.strictObject({
	/**
	 * Database ID of the flight slice in the booking segment
	 */
	flight_id: FlightSliceIdZ.optional(),
	luggage_status: z.string(),
	/** The number of available seats if known */
	remaining_seats_amount: z.number().nullable(),
	segments: z.array(FlightBookingSliceSegmentZ),
})

export type FlightBookingSlice = z.infer<typeof FlightBookingSliceZ>

export const FlightBookingSegmentZ = z.strictObject({
	__original_total_price: OriginalAmountZ,
	__original_total_tax: OriginalAmountZ,
	actual_price: z.null(),
	baggage_ancillaries: z.null(),
	booking_segment_to_travelers: z.array(FlightBSTTZ),
	booking_type: z.null(),
	cancellation_status: z.null(),
	cloned_from_id: BookingSegmentIdZ.nullable(),
	cvv_required: z.null(),
	flights: z.array(FlightBookingSliceZ),
	hold_luggage_price: z.number().optional(),
	/**
	 * Database ID of the booking segment
	 */
	id: BookingSegmentIdZ,
	in_policy: z.boolean(),
	other_vendor: z.null(),
	penalties: FlightPenaltiesZ.nullable(),
	policy_deviations: z.array(FlightPolicyDeviationZ),
	/** @deprecated */
	res_id: FlightBookingSegmentResIdZ,
	reservation_status: z.string(),
	search: z.strictObject({
		price_metadata: PricesMetadataZ.nullable(),
		co2_metadata: StatsBucketsZ.nullish(),
	}),
	search_id: SearchIdZ,
	ticket_by_date_updated: z.boolean(),
	ticket_by_date: z.string(),
	ticket_changeable: z.boolean(),
	ticket_refundable: z.boolean(),
	total_price: z.number(),
	total_tax: z.number(),
	travelers: z.array(UserMiniZ),
	uta: BookingOptionUTAZ,
	vendor_specific_info: z.null(),
	vendor: FlightProviderZ,
})

export type FlightBookingSegment = z.infer<typeof FlightBookingSegmentZ>

export const FlightCreateBookingSegmentRequestZ = z.strictObject({
	flight_identifier: FlightIdentifierZ,
	original_booking_segment_id: BookingSegmentIdZ.optional(),
	search_id: SearchIdZ,
})

export type FlightCreateBookingSegmentRequest = z.infer<typeof FlightCreateBookingSegmentRequestZ>

export const FlightCreateBookingSegmentResponseZ = getJobResultZ(
	z.strictObject({
		booking_segment_id: BookingSegmentIdZ,
		price_change: FlightPriceChangeZ.optional(),
	}),
)

export type FlightCreateBookingSegmentResponse = z.infer<typeof FlightCreateBookingSegmentResponseZ>
