import { TFunction } from 'i18next'
import sumBy from 'lodash/sumBy'
import React from 'react'

import { Font } from 'src/atoms/GenericComponents'
import { Stack } from 'src/atoms/System'
import { getRelatedFees } from 'src/clients/gg/usageDetails'
import { filterEmptyValues } from 'src/lib/array-utils'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { isFeeWithFailedPaymentStatus } from 'src/lib/isFeeWithFailedPaymentStatus'
import { FontFamily } from 'src/styles'
import { Fee, ServiceFeeType, TripFee } from 'src/travelsuit/fees.types'
import { fullName } from 'src/travelsuit/users.utils'
import { Fee as ApiFee } from 'src/types/usageDetails'

import { FeeCardWithPayment } from './FeeCardWithPayment'
import { FeePaymentRow } from './FeePaymentRow'

function getFeeData(fee: ApiFee, t: TFunction) {
	const serviceType = fee.service_type

	if (serviceType === ServiceFeeType.Trip) {
		return {
			data: fee.trip,
			service: t('trip-fee', 'Trip fee'),
		}
	}

	if (serviceType === ServiceFeeType.VirtualPaymentAutomation) {
		return {
			data: fee.vpa,
			service: fee.vpa.bs_info.service_name,
		}
	}

	return null
}

interface TripFeeCardBaseProps<T extends TripFee> {
	fee: Fee<T>
	onFeesReload: () => void
	primaryInfo: string
	getFeeDetailsContent?: (defaultContent: React.ReactNode) => React.ReactNode
}

export function TripFeeCardBase<T extends TripFee>({
	fee,
	onFeesReload,
	primaryInfo,
	getFeeDetailsContent,
}: TripFeeCardBaseProps<T>) {
	const { t } = useTranslation()

	const {
		trip: { id: tripId },
		user,
		currency,
		id,
	} = fee[fee.service_type]

	return (
		<FeeCardWithPayment
			fee={fee}
			secondaryChipText={t('usage-details.fees.trip-id', 'Trip ID - {{tripId}}', { tripId })}
			primaryInfo={primaryInfo}
			invoiceProfile={user.invoice_profile}
			travelerName={fullName(user)}
			onFeesReload={onFeesReload}
			getFeeDetailsContent={getFeeDetailsContent}
			renderFeePaymentDetails={async () => {
				const relatedFees = await getRelatedFees({ feeId: id })

				const rows = filterEmptyValues(
					relatedFees.concat(fee as unknown as ApiFee).map((fee) => {
						const feeData = getFeeData(fee, t)

						if (!feeData || !isFeeWithFailedPaymentStatus(feeData.data)) {
							return null
						}

						return {
							id: feeData.data.id,
							label:
								fee.service_type === ServiceFeeType.Trip
									? t('trip-fee', 'Trip fee')
									: t('virtual-payment-automation-fee-for-service', 'VPA card issuance fee for {{service}}', {
											service: feeData.service,
										}),
							price: feeData.data.total_price,
						}
					}),
				).sort((left, right) => left.id - right.id)

				return (
					<Stack gap={2} data-test="FeePaymentDetails">
						<Font size={12} family={FontFamily.OpenSans} data-test="FeePaymentDescription">
							{t(
								'traveler-trip-failed-to-charge-fees',
								'The following fees for traveler {{traveler}} in trip {{tripId}} were unable to be charged.',
								{ traveler: fullName(user), tripId },
							)}{' '}
							{t('usage-details.pay-again-modal.select-card', 'Please select a credit card and try again.')}
						</Font>
						<Stack gap={1}>
							{rows.map((row) => {
								return <FeePaymentRow key={row.id} label={row.label} price={row.price} currency={currency} />
							})}
							<FeePaymentRow
								label={t('usage-details.fees.total-fee', 'Total fee')}
								price={sumBy(rows, 'price')}
								key="totalFee"
								currency={currency}
								boldPrice
							/>
						</Stack>
					</Stack>
				)
			}}
		/>
	)
}
