import React, { useState } from 'react'

import Button from 'src/atoms/Button/Button'
import Checkbox from 'src/atoms/Checkbox/Checkbox'
import Loader from 'src/atoms/Loader'
import Select from 'src/atoms/Select/Select'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { CreditCardPaymentMethod, PaymentMethod } from 'src/lib/payment-methods'
import { InvoiceProfile } from 'src/travelsuit'

import { PaymentMethodsAutocomplete } from '../TripPaymentMethod/PaymentMethodsAutocomplete'
import { StyledModal } from './FeeCard.components'

interface IProps {
	onClose: () => void
	onConfirm: (paymentMethod: PaymentMethod, isCardDefault?: boolean) => void
	isLoading: boolean
	subtitle: React.ReactNode
	defaultInvoiceProfile?: InvoiceProfile | null
	paymentMethods: PaymentMethod[]
	defaultPaymentMethod?: PaymentMethod | null
	canChangeDefaultCard?: boolean
}

const PaymentModal: React.FC<IProps> = ({
	onClose,
	onConfirm,
	isLoading,
	subtitle,
	defaultInvoiceProfile,
	paymentMethods,
	defaultPaymentMethod,
	canChangeDefaultCard,
}) => {
	const { t } = useTranslation()
	const [paymentMethod, setPaymentMethod] = useState(defaultPaymentMethod)
	const [isCardDefault, setCardDefault] = useState(false)

	const isPaymentMethodDisabled = paymentMethod?.getIsDisabled()

	const allMethodsDisabled = paymentMethods.every((option) => option.getIsDisabled())
	const isConfirmDisabled =
		!paymentMethod || !paymentMethods || allMethodsDisabled || isLoading || isPaymentMethodDisabled

	const isDefaultCardConfigurationAvailable =
		canChangeDefaultCard && (!paymentMethod || paymentMethod instanceof CreditCardPaymentMethod)

	const isSelectedMethodEqualToDefault = paymentMethod?.getId() === defaultPaymentMethod?.getId()

	return (
		<StyledModal
			onClose={onClose}
			title={t('usage-details.fees.pay-again', 'Pay Again')}
			controlButtons={[
				<Button
					key="ok"
					width={160}
					color="bluePrimary"
					onClick={() => {
						if (paymentMethod) {
							onConfirm(paymentMethod, isDefaultCardConfigurationAvailable && isCardDefault)
						}
					}}
					disabled={isConfirmDisabled}
				>
					{t('usage-details.pay-again-modal.button')}
				</Button>,
			]}
		>
			{paymentMethods && !isLoading ? (
				<>
					{subtitle}
					<Select
						value={defaultInvoiceProfile}
						label={t('usage-details.pay-again-modal.invoice-profile', 'Invoice Profile Name')}
						options={[
							{
								label: <span>{defaultInvoiceProfile?.display_name}</span>,
								value: defaultInvoiceProfile,
							},
						]}
						renderValue={(invoice) => invoice.display_name}
						placeholder={t('usage-details.pay-again-modal.invoice-profile', 'Invoice Profile Name')}
						disabled
						renderInModal
						required
					/>
					<PaymentMethodsAutocomplete
						paymentMethods={paymentMethods}
						selectedMethod={allMethodsDisabled ? null : paymentMethod}
						disabled={!paymentMethods.length}
						onChange={setPaymentMethod}
						error={!paymentMethod || allMethodsDisabled || isPaymentMethodDisabled}
						helperText={
							allMethodsDisabled
								? t(
										'usage-details.pay-again-modal.no-available-cards',
										'No credit cards available. Please add the card in the traveler profile or invoice profile',
									)
								: ''
						}
					/>
					{isDefaultCardConfigurationAvailable && (
						<Checkbox
							checked={isCardDefault || isSelectedMethodEqualToDefault}
							label={t('add-credit-card.select-as-default-for-fees', 'Set as a default card for fees')}
							onToggle={setCardDefault}
							disabled={isSelectedMethodEqualToDefault || isPaymentMethodDisabled}
						/>
					)}
				</>
			) : (
				<Loader />
			)}
		</StyledModal>
	)
}

export default PaymentModal
