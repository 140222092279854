import React from 'react'
import { useTranslation } from 'react-i18next'

import { useShowModal } from 'src/atoms/ModalApi'
import { parsePaymentMethodFromContainer, PaymentMethod } from 'src/lib/payment-methods'
import { useFetchData } from 'src/lib/react-hooks/useFetchData'
import { InvoiceProfile, WithPaymentMethods } from 'src/travelsuit'
import { Fee, FeesData } from 'src/travelsuit/fees.types'

import { parseFeesPaymentOptions } from '../FeesCardOption'
import BaseFeeCard from './BaseFeeCard'
import { FeeStatusBadge } from './FeeStatusBadge'
import PaymentError from './PaymentError'
import PaymentModal from './PaymentModal'
import usePayAgainFee from './usePayAgainFee'

interface FeeCardWithPaymentProps<T extends FeesData> {
	fee: Fee<T>
	onFeesReload: () => void
	invoiceProfile?: InvoiceProfile | null
	travelerName?: string
	primaryInfo: string
	secondaryChipText?: string
	canChangeDefaultCard?: boolean
	displayInvoiceProfileNameForSubscription?: boolean
	getFeeDetailsContent?: (defaultContent: React.ReactNode) => React.ReactNode
	statusActions?: React.ReactNode
	additionalPriceDetails?: { label: string; amount: number }[]
	renderFeePaymentDetails?: () => Promise<React.ReactNode>
}

export function FeeCardWithPayment<T extends FeesData>({
	fee,
	onFeesReload,
	invoiceProfile,
	primaryInfo,
	travelerName,
	secondaryChipText,
	canChangeDefaultCard,
	displayInvoiceProfileNameForSubscription,
	getFeeDetailsContent,
	statusActions,
	additionalPriceDetails,
	renderFeePaymentDetails,
}: FeeCardWithPaymentProps<T>) {
	const { t } = useTranslation()

	const show = useShowModal()

	const { handlePaymentRequest, isRequestInProgress } = usePayAgainFee(onFeesReload)

	const { fetchData } = useFetchData<WithPaymentMethods>()

	const feeServiceDetails = fee[fee.service_type]
	const { id: feeId, status } = feeServiceDetails

	const onConfirm = async (paymentMethod: PaymentMethod, isCardDefault?: boolean) => {
		const shouldHandleDefaultCardChange = canChangeDefaultCard && isCardDefault

		try {
			await handlePaymentRequest(feeId, paymentMethod)
		} catch (error) {
			if (shouldHandleDefaultCardChange) {
				onFeesReload?.()
			}
			show(({ close }) => <PaymentError onClose={close} error={error} />)
		}
	}

	const openPayAgainModal = async () => {
		const [{ data }, content] = await Promise.all([
			fetchData({ resourcePath: `fee/${feeId}/payment_methods` }),
			renderFeePaymentDetails?.() ??
				Promise.resolve(t('usage-details.pay-again-modal.select-card', 'Please select a credit card and try again.')),
		])

		show(({ close }) => {
			return (
				<PaymentModal
					subtitle={content}
					onClose={close}
					onConfirm={(...args) => onConfirm(...args).then(close)}
					isLoading={isRequestInProgress}
					defaultInvoiceProfile={invoiceProfile}
					paymentMethods={parseFeesPaymentOptions(data, t)}
					defaultPaymentMethod={parsePaymentMethodFromContainer(feeServiceDetails)}
					canChangeDefaultCard={canChangeDefaultCard}
				/>
			)
		})
	}

	return (
		<BaseFeeCard
			fee={fee}
			country={invoiceProfile?.country}
			secondaryChipText={secondaryChipText}
			primaryInfo={primaryInfo}
			invoiceProfileName={invoiceProfile?.display_name || ''}
			traveler={travelerName}
			status={<FeeStatusBadge status={status} />}
			onPayAgainClick={openPayAgainModal}
			displayInvoiceProfileNameForSubscription={displayInvoiceProfileNameForSubscription}
			getFeeDetailsContent={getFeeDetailsContent}
			statusActions={statusActions}
			additionalPriceDetails={additionalPriceDetails}
		/>
	)
}
