import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import ReactTooltip, { GetContentFunc, Place, TooltipProps } from 'react-tooltip'
import { useUID } from 'react-uid'

import { MobileClickArea } from 'src/atoms/MobileClickArea'
import { Portal } from 'src/common/overlays/Portal'
import { mainBlack } from 'src/refactor/colors'
import styled, { css, FontFamily } from 'src/styles'

export type TooltipPlace = Place

export type IProps<C extends React.ComponentType<any> | keyof JSX.IntrinsicElements = 'span'> = Pick<
	TooltipProps,
	'place'
> & {
	children?: React.ReactNode
	title?: string
	tipClassName?: string
	render?: GetContentFunc
	component?: C
	disable?: boolean
	multiline?: boolean
	autoWidth?: boolean
}

const useTooltipStyles = makeStyles(() => ({
	root: {
		position: 'relative',
	},
}))

const OFFSET_VARIABLE_NAME = '--offset'
const MOBILE_SCREEN_OFFSET_PX = 15
const TOOLTIP_ARROW_MIN_OFFSET = 20

const StyledReactTooltip = styled(ReactTooltip)<{ fixedWidth?: boolean }>`
	font-family: ${FontFamily.Comfortaa};
	font-size: ${(p) => p.theme.typography.pxToRem(12)};
	font-weight: bold;
	line-height: 1.5;

	border-radius: 2px;
	padding: ${(p) => p.theme.spacing(2)}px;

	${(p) =>
		p.fixedWidth
			? css`
					max-width: 350px;
					white-space: wrap;
				`
			: ''}

	${(p) => p.theme.breakpoints.down('xs')} {
		max-width: calc(100vw - ${MOBILE_SCREEN_OFFSET_PX * 2}px);
	}

	&.show {
		background: ${mainBlack};
		z-index: 99999999;
	}

	&.place-top,
	&.place-bottom,
	&.place-left,
	&.place-right {
		&.show {
			opacity: 1;
		}
	}

	&.place-top.show,
	&.place-bottom.show {
		&::before {
			margin-left: var(${OFFSET_VARIABLE_NAME});
		}

		&::after {
			margin-left: var(${OFFSET_VARIABLE_NAME});
		}
	}
`

const TOOLTIP_ARROW_HALF_WIDTH = 6

function getElementCenterXOffset(rect: DOMRect) {
	return rect.left + rect.width / 2
}

function overrideTooltipArrowPosition(targetElement: Element, tooltipElement: HTMLElement) {
	if (!tooltipElement.classList.contains('place-top') && !tooltipElement.classList.contains('place-bottom')) {
		return
	}

	const targetRect = targetElement.getBoundingClientRect()
	const tooltipRect = tooltipElement.getBoundingClientRect()

	const offsetXDiff =
		getElementCenterXOffset(targetRect) - getElementCenterXOffset(tooltipRect) - TOOLTIP_ARROW_HALF_WIDTH

	const tooltipMaxOffset = tooltipRect.width / 2 - TOOLTIP_ARROW_MIN_OFFSET

	tooltipElement.style.setProperty(
		OFFSET_VARIABLE_NAME,
		`${Math.max(-tooltipMaxOffset, Math.min(offsetXDiff, tooltipMaxOffset))}px`,
	)
}

export default function Tooltip<C extends React.ComponentType<any> | keyof JSX.IntrinsicElements = 'span'>(
	props: IProps<C> & Omit<React.ComponentPropsWithoutRef<'span'>, keyof IProps<C>>,
) {
	const {
		className,
		tipClassName,
		children,
		title,
		component: Component = 'span',
		render,
		place,
		disable,
		multiline,
		autoWidth,
		...others
	} = props
	const id = 'tt-' + useUID()

	const styles = useTooltipStyles()
	const componentCls = classNames(styles.root, className)

	const content = render?.(title ?? '') ?? title
	if (!content) {
		return (
			<Component className={componentCls} {...others}>
				{children}
			</Component>
		)
	}

	return (
		<React.Fragment>
			<Component className={componentCls} data-for={id} data-tip {...others}>
				<MobileClickArea />
				{children}
			</Component>
			<Portal>
				<StyledReactTooltip
					id={id}
					fixedWidth={!autoWidth}
					className={tipClassName}
					type="dark"
					disable={disable}
					getContent={() => content}
					place={place}
					effect="solid"
					multiline={multiline}
					overridePosition={({ left, top }, _currentEvent, currentTarget, node) => {
						// ReactTooltip v4 positions itself incorrectly on the narrow screens
						// even if the tooltip width is less that the view port.
						if (node) {
							const d = document.documentElement
							left = Math.max(
								MOBILE_SCREEN_OFFSET_PX,
								Math.min(d.clientWidth - node.clientWidth - MOBILE_SCREEN_OFFSET_PX, left),
							)
							top = Math.max(0, Math.min(d.clientHeight - node.clientHeight, top))

							requestAnimationFrame(() => overrideTooltipArrowPosition(currentTarget as Element, node))
						}
						return { top, left }
					}}
				/>
			</Portal>
		</React.Fragment>
	)
}

Tooltip.hide = ReactTooltip.hide
