import axios, { AxiosError } from 'axios'
import moment from 'moment'

import { appConfig } from 'src/app-config/appConfig'
import { setVerificationRedirectData } from 'src/lib/redirect-utils'
import { redirectTo3DSVerification } from 'src/lib/redirectTo3DSVerification'
import { loadData } from 'src/lib/requests'
import { entityGenerator } from 'src/lib/utils'
import { BookTripDetailsDefault as BookTripDetails } from 'src/organisms/ItinerarySubmit/ItinerarySubmit.helpers'
import { ItineraryActions, TripActions, TripApprovalsActions, TripPaymentMethodsActions } from 'src/redux/actions'
import { getStore } from 'src/redux/stores'
import {
	brightRed,
	darkBlue,
	darkRed,
	linkBlue,
	purple,
	secondaryBlack,
	successGreen,
	yellow,
} from 'src/refactor/colors'

import {
	AllTripStatuses,
	BookingSegmentToTraveler,
	CreditCardResponse,
	HTTPMethod,
	InvoiceProfileStatus,
	ItineraryRefresh,
	Trip,
	TripApproval,
	TripDirection,
	TripPaymentMethodConfigPayload,
	TripStatus,
	TripStatusVirtual,
	User,
	VoidCallback,
} from './index'
import { WorldlineOrder, WorldlineOrderStatus } from './worldline'

export const emptyTrip = entityGenerator<Trip>({
	purpose_list: [],
	id: 0,
	status: TripStatus.Draft,
	trip_type: TripDirection.RoundTrip,
	include_flights: true,
	include_accommodations: false,
	travelers_count: 0,
	travelers: [],
	flights_comments: null,
	hotels_comments: null,
	cars_comments: null,
	products: [],
	approval_statuses: [],
	support_requests: [],
	purpose: [],
	total_cost_billed: [],
	total_cost: 0,
	sub_trips: [],
	end_date: '',
	start_date: '',
})

export interface TripFeeStatusData {
	user: User
	total_price: number
	total_tax: number
	status: WorldlineOrderStatus
	order: WorldlineOrder
	credit_card: CreditCardResponse
}

export type BookingSegmentToTravelerUpdates = Pick<
	BookingSegmentToTraveler,
	'id' | 'booking_segment_id' | 'booking_errors' | 'user_id' | 'status'
>

export interface TripBookingStatusResponse {
	status: TripStatus
	errors: ErrorsInBooking[]
	trip_fees: TripFeeStatusData[]
	booking_segment_to_travelers: BookingSegmentToTravelerUpdates[]
}

export function prepareTripJson(trip: Partial<Trip>) {
	trip = { ...trip }
	delete trip.id
	delete trip.calculatedStatus
	return trip
}

export function calculateTripStatus(trip: Trip): AllTripStatuses {
	const now = moment.now()
	if (!trip.products?.length) {
		return trip.status
	}

	const firstDest = trip.products[0]
	const startTime = moment(firstDest.start_time)
	const endTime = moment(trip.products[trip.products.length - 1].end_time)

	if (trip.status !== TripStatus.Booked) {
		return trip.status
	}

	if (endTime.valueOf() < now) {
		return TripStatusVirtual.PastTrip
	}

	if (startTime.subtract(1, 'week').valueOf() <= now) {
		return TripStatusVirtual.Upcoming
	}

	if (startTime.valueOf() >= now) {
		return TripStatus.Booked
	}

	return TripStatusVirtual.TravelingNow
}

export type TripStatusTabType = TripStatus | TripStatusVirtual

export const tripOrderByStatus = (tripStatus: TripStatusTabType) =>
	({
		[TripStatus.WaitingApproval]: 3,
		[TripStatus.SendingToApproval]: 3,
		[TripStatus.Draft]: 2,
		[TripStatus.DraftBySupport]: 1,
		[TripStatus.Reverted]: 0,
		[TripStatus.Booked]: 4,
		[TripStatus.NotApproved]: 1,
		[TripStatus.Canceled]: 6,
		[TripStatusVirtual.PastTrip]: 1,
		[TripStatusVirtual.Rejected]: 6,
		[TripStatusVirtual.TravelingNow]: 2,
		[TripStatusVirtual.Upcoming]: 3,
		[TripStatus.Approved]: 6,
		[TripStatus.Booking]: 6,
		[TripStatus.PriceChanged]: 6,
		[TripStatus.PreTripGreenLight]: 6,
		[TripStatus.WaitingForUserSubmission]: 6,
		[TripStatus.UrgentActionRequired]: 6,
	})[tripStatus]

const baseUrl = appConfig.API_DOMAIN

// TASK move to redux actions
export async function getApprovalStatus(tripId: number): Promise<TripApproval[]> {
	const { data } = await loadData<TripApproval[]>({ resourcePath: `trips/${tripId}/approvals_status` })
	return data
}

// TASK move to redux actions
export async function refreshPrices(tripId: number, callback?: VoidCallback<string>, errCallback?: VoidCallback<any>) {
	const {
		data: { id, status },
	} = await loadData<ItineraryRefresh>({ method: HTTPMethod.PUT, resourcePath: `trips/${tripId}/itinerary/refresh` })

	let lastStatus
	try {
		if (['sent', 'created'].includes(status)) {
			lastStatus = await checkItineraryRefreshStatus(tripId, id)
		} else {
			lastStatus = status
		}
		callback?.(lastStatus)
		return getStore().dispatch(ItineraryActions.getItinerary(tripId))
	} catch (e) {
		errCallback?.(e)
		return false
	}
}

async function checkItineraryRefreshStatus(tripId: number, statId: number): Promise<string> {
	const {
		data: { status },
	} = await loadData<ItineraryRefresh>({ resourcePath: `trips/${tripId}/itinerary/refresh/${statId}` })

	if (status === 'success' || status === 'terms_changed') {
		return status
	}

	if (status === 'failed') {
		throw new Error('failed updating prices for trip')
	}

	return new Promise<string>((resolve) => {
		setTimeout(() => resolve(checkItineraryRefreshStatus(tripId, statId)), 2000)
	})
}

export function isTripBooked(trip: Trip) {
	return [TripStatus.Booked].includes(trip.status)
}

export async function downloadItinerary(tripId: number, userId?: number | 'all') {
	const ext = userId === 'all' ? 'zip' : 'pdf'
	const hash = await axios.get<string>(`${baseUrl}/trips/${tripId}/itinerary/hash`, {
		params: { user_id: userId },
	})
	return `${baseUrl}/trips/${tripId}/itinerary/${hash.data}.${ext}`
}

export async function downloadCalendarEvent(tripId: number, userId: number) {
	const hash = await axios.get<string>(`${baseUrl}/trips/${tripId}/itinerary/hash`, {
		params: { user_id: userId },
	})
	return `${baseUrl}/trips/${tripId}/itinerary/${hash.data}.ics`
	// additional options
	// return `webcal${baseUrl?.substring(4)}/trips/${tripId}/itinerary/${hash.data}.ics`
	// return 'http://www.google.com/calendar/event?action=TEMPLATE&dates=20200206T010000Z%2F20200208T010000Z&text=test&location=test%20location&details=test%20description'
}

export const StatusColorMap: Partial<Record<TripStatus | TripStatusVirtual | InvoiceProfileStatus, string>> = {
	[TripStatus.Draft]: darkBlue,
	[TripStatus.DraftBySupport]: darkBlue,
	[TripStatus.Booking]: linkBlue,
	[TripStatus.PriceChanged]: linkBlue,
	[TripStatus.Approved]: purple,
	[TripStatus.WaitingApproval]: yellow,
	[InvoiceProfileStatus.ConfirmationInProgress]: yellow,
	[InvoiceProfileStatus.TermsOfServiceValidationPending]: yellow,
	[InvoiceProfileStatus.Active]: successGreen,
	[TripStatus.Booked]: successGreen,
	[TripStatusVirtual.PastTrip]: purple,
	[TripStatus.Canceled]: secondaryBlack,
	[TripStatus.NotApproved]: darkRed,
	[TripStatusVirtual.TravelingNow]: successGreen,
	[TripStatusVirtual.Upcoming]: yellow,
	[TripStatus.Reverted]: darkBlue,
	[TripStatus.WaitingForUserSubmission]: purple,
	[TripStatus.UrgentActionRequired]: brightRed,
}

/* #region  BookingError */
export enum BookingErrorReason {
	MissingInfo = 'missing_info',
	ServerError = 'server_error',
	PriceChange = 'price_change',
	PaymentPermissionDenied = 'payment_permission_denied',
}

export enum BookingSource {
	SubmitForApproval = 'sent_for_approval',
	ApproveOrReject = 'approve_or_reject',
	Book = 'book',
	BookBySupport = 'book_by_support',
}

export class BookingError extends Error {
	public reason: BookingErrorReason
	public source: BookingSource
	public message!: string
	public originalError: Error | undefined
	public errors: Array<ErrorsInBooking> | undefined

	private static REASON_LABELS: Record<BookingErrorReason, string> = {
		[BookingErrorReason.MissingInfo]: 'Missing Details',
		[BookingErrorReason.ServerError]: 'Booking Error',
		[BookingErrorReason.PriceChange]: 'Price Change',
		[BookingErrorReason.PaymentPermissionDenied]: 'Payment Permission Denied',
	}

	private static SOURCE_LABELS: Record<BookingSource, string> = {
		[BookingSource.ApproveOrReject]: 'Approve Or Reject',
		[BookingSource.Book]: 'Book',
		[BookingSource.BookBySupport]: 'Book by Support',
		[BookingSource.SubmitForApproval]: 'Sent for Approval',
	}

	constructor(
		reason: BookingErrorReason,
		source: BookingSource,
		originalError?: Error,
		errors?: Array<ErrorsInBooking>,
	) {
		const reasonString = BookingError.reasonToString(reason)
		super(reasonString)
		this.reason = reason
		this.source = source
		this.originalError = originalError
		this.errors = errors
	}

	public static reasonToString(reason: BookingErrorReason): string {
		return BookingError.REASON_LABELS[reason]
	}

	public static sourceToString(source: BookingSource): string {
		return BookingError.SOURCE_LABELS[source]
	}

	public get reasonString(): string {
		return BookingError.reasonToString(this.reason)
	}

	public get sourceString(): string {
		return BookingError.sourceToString(this.source)
	}
}
/* #endregion */

export class PriceChangeBookingError extends BookingError {}

export class BookingValidationError extends BookingError {}

/* #region Submit Trip */

const defaultShouldStopPolling = ({ status }: TripBookingStatusResponse) => status !== TripStatus.Approved

// TASK move to getStore() via actions. Example: src/redux/actions/reports.actions.ts:35:2
export async function pollBookingStatus(
	tripId: number,
	shouldStopPolling = defaultShouldStopPolling,
): Promise<TripBookingStatusResponse> {
	let data: TripBookingStatusResponse
	let errorsCount = 0

	// eslint-disable-next-line no-constant-condition
	while (true) {
		try {
			data = await new Promise<TripBookingStatusResponse>((resolve, reject) => {
				getStore().dispatch(
					TripActions.getBookingStatus(
						tripId,
						(data) => resolve(data),
						(error) => reject(error),
					),
				)
			})

			if (shouldStopPolling(data)) {
				break
			}

			await new Promise((resolve) => setTimeout(resolve, 2000))
		} catch (e) {
			if (errorsCount > 5) {
				throw e
			}

			errorsCount += 1
			await new Promise((resolve) => setTimeout(resolve, 2000))
		}
	}

	return data
}

export enum BookingErrorCode {
	CarsCarTypeOnRequest = 'cars - car_type_on_request',
	CarsLocationWorkingHours = 'cars - location_working_hours',
	CarsIncorrectName = 'cars - incorrect_name',
	CarsWrongNumberCardLoyaltyCard = 'cars - wrong_number_card_loyalty_card',
}

export interface ErrorMessages {
	dev_message: string
	error_code: BookingErrorCode
	message: string
	reason: string
}

export enum ApiBookingErrorReason {
	FareNotAvailable = 'fare_not_available',
	InvalidName = 'invalid_name',
	CreditCardIssue = 'credit_card_issue',
	UnknownError = 'unknown_error',
	ThirdPartyConnectionError = 'third_party_connection_error',
}

export interface ErrorsInBooking extends ErrorMessages {
	user_error: string
	booking_segment_id: number
	error_type: ApiBookingErrorReason | null
}

/** Throws `BookingError` on failure */
interface SubmitProps extends Partial<BookTripDetails> {
	trip: Trip
	source: BookingSource
}

export async function checkTripBookingResultErrors({
	tripId,
	changes,
	source,
}: {
	tripId: number
	changes: TripBookingStatusResponse
	source: BookingSource
}) {
	if (changes?.status === TripStatus.Reverted) {
		throw new BookingError(BookingErrorReason.ServerError, source, undefined, changes.errors)
	}

	if (changes?.status === TripStatus.WaitingApproval) {
		getStore().dispatch(TripApprovalsActions.getTripApprovals(tripId))
	}

	if (changes?.status === TripStatus.Draft) {
		throw new BookingError(BookingErrorReason.PriceChange, source)
	}

	if (changes?.status === TripStatus.PriceChanged) {
		throw new PriceChangeBookingError(BookingErrorReason.PriceChange, source)
	}

	const statusUpdate = await pollBookingStatus(tripId, ({ status }) => status !== TripStatus.SendingToApproval)
	if (statusUpdate && statusUpdate.status === TripStatus.Draft) {
		throw new BookingError(BookingErrorReason.ServerError, source, undefined, statusUpdate.errors)
	}
}

function handleTripBookingError(err: AxiosError, source: BookingSource) {
	if (err.response?.status === 412) {
		throw new BookingError(BookingErrorReason.MissingInfo, source, err)
	}

	if (err.response?.status === 400) {
		throw new BookingValidationError(BookingErrorReason.ServerError, source, err)
	}

	if (err.response?.status !== 403) {
		throw new BookingError(BookingErrorReason.ServerError, source, err)
	}

	throw new BookingError(BookingErrorReason.ServerError, source, err)
}

export async function pollTripStatusWithHandlingErrorsAndRedirects(tripId: number, source: BookingSource) {
	const changes = await pollBookingStatus(
		tripId,
		({ status, trip_fees }) =>
			![TripStatus.Approved, TripStatus.SendingToApproval].includes(status) ||
			trip_fees.some(({ status }) => status === WorldlineOrderStatus.Redirected),
	)
	const redirect = changes.trip_fees.find(({ status }) => status === WorldlineOrderStatus.Redirected)?.order
		?.redirect_url
	if (redirect) {
		setVerificationRedirectData<{ trip_id: number }>({
			returnToUrl: `/trips/${tripId}/itinerary`,
			data: { trip_id: tripId },
		})
		redirectTo3DSVerification(redirect)
	} else {
		await checkTripBookingResultErrors({ tripId, changes, source })
	}
	return changes
}

export async function setPaymentMethod(tripId: number, tripPaymentMethodPayload: TripPaymentMethodConfigPayload) {
	try {
		await updatePaymentMethod(tripId, tripPaymentMethodPayload)
	} catch (e) {
		if (e.isAxiosError && e.response.data.code === 'permission_denied') {
			throw new BookingError(BookingErrorReason.PaymentPermissionDenied, BookingSource.Book, e)
		} else {
			throw e
		}
	}
}

export async function submitTrip({
	trip,
	message,
	source,
	trip_name,
	fake_booking,
	card_cvc_data,
	selected_documents,
	report_settings,
}: SubmitProps): Promise<void> {
	const tripId = trip.id!

	return new Promise((res, rej) => {
		const _handleSuccess = async () => {
			pollTripStatusWithHandlingErrorsAndRedirects(tripId, source).then(() => res(), rej)
		}

		function _handleErr(err: AxiosError) {
			try {
				handleTripBookingError(err, source)
			} catch (e) {
				rej(e)
			}
		}

		getStore().dispatch(
			TripActions.submitTrip(
				tripId,
				source,
				{ trip_name, message, selected_documents, fake_booking, card_cvc_data, report_settings },
				_handleSuccess,
				_handleErr,
			),
		)
	})
}

async function updatePaymentMethod(tripId: number, tripPaymentMethodPayload: TripPaymentMethodConfigPayload) {
	return new Promise((resolve, reject) =>
		getStore().dispatch(
			TripPaymentMethodsActions.updatePaymentMethod(tripId, tripPaymentMethodPayload, resolve, reject),
		),
	)
}

export async function checkTripStatusAfterVerification({ tripId }: { tripId: number }) {
	const statusUpdate = await pollBookingStatus(
		tripId,
		({ status }) => ![TripStatus.Booking, TripStatus.SendingToApproval].includes(status),
	)

	if (statusUpdate?.status === TripStatus.Booked) {
		return statusUpdate
	}

	throw new BookingError(BookingErrorReason.ServerError, BookingSource.Book, undefined, statusUpdate.errors)
}

export const getApproverName = (trip: Trip) => {
	const approver = trip.approval_statuses[0]?.user
	const approverName = `${approver?.first_name} ${approver?.last_name}`
	const isTripStatusWaitingForUserSubmission =
		trip.calculatedStatus === TripStatus.WaitingForUserSubmission || trip.status === TripStatus.WaitingForUserSubmission

	return isTripStatusWaitingForUserSubmission ? approverName : undefined
}
