import React from 'react'
import { useTranslation } from 'react-i18next'

import { StatusBadge } from 'src/atoms/StatusBadge'
import Tooltip from 'src/atoms/Tooltip/Tooltip'
import { isFeeWithFailedPaymentStatus } from 'src/lib/isFeeWithFailedPaymentStatus'
import { backgroundGray, brightRed, mainBlack } from 'src/refactor/colors'
import { WorldlineOrderStatus } from 'src/travelsuit/worldline'

export interface FeeStatusBadgeProps {
	status: WorldlineOrderStatus | null
}

export function FeeStatusBadge({ status, children }: React.PropsWithChildren<FeeStatusBadgeProps>) {
	const { t } = useTranslation()

	if (status === WorldlineOrderStatus.PartialRefund) {
		return (
			<StatusBadge
				hasReducedPadding
				transformToUpperCase={false}
				applyMargin={false}
				color={brightRed}
				label={
					<>
						{t('usage-details.fees.status.partially-refunded', 'Partially refunded')}
						{children}
					</>
				}
			/>
		)
	}

	if (status === WorldlineOrderStatus.Refunded) {
		return (
			<StatusBadge
				hasReducedPadding
				transformToUpperCase={false}
				applyMargin={false}
				color={brightRed}
				label={
					<>
						{t('usage-details.fees.status.refunded', 'Refunded')}
						{children}
					</>
				}
			/>
		)
	}

	if (status === WorldlineOrderStatus.RefundPending) {
		return (
			<StatusBadge
				hasReducedPadding
				transformToUpperCase={false}
				applyMargin={false}
				color={brightRed}
				label={t('usage-details.fees.status.refund-pending', 'Refund pending')}
			/>
		)
	}

	if (isFeeWithFailedPaymentStatus({ status })) {
		return (
			<StatusBadge
				hasReducedPadding
				transformToUpperCase={false}
				applyMargin={false}
				color={brightRed}
				label={t('usage-details.fees.status.failed', 'Payment failed')}
			/>
		)
	}

	if (status === WorldlineOrderStatus.CapturePending || status === WorldlineOrderStatus.Validating) {
		return (
			<Tooltip
				title={t(
					'itinerary-breakdown.trip-fee-processing',
					'We are still trying to finalize your trip. Processing time may vary depending on the bank. If your payment fails, we will notify you.',
				)}
			>
				<StatusBadge
					hasReducedPadding
					transformToUpperCase={false}
					color={backgroundGray}
					fontColor={mainBlack}
					label={t('usage-details.fees.status.processing', 'Charge in progress')}
				/>
			</Tooltip>
		)
	}

	return null
}
