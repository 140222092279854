import React from 'react'

import RefundDateIcon from 'src/assets/refund-date.svg'
import Chip from 'src/atoms/Chip/Chip'
import { CountryFlag } from 'src/atoms/CountryFlag'
import { Flex } from 'src/atoms/GenericComponents/GenericComponents'
import useTranslation from 'src/lib/i18n/UseTranslation'
import { isFeeWithFailedPaymentStatus } from 'src/lib/isFeeWithFailedPaymentStatus'
import { routeFor, Routes } from 'src/lib/route-utils'
import { formatPrice } from 'src/lib/utils'
import { formatDate } from 'src/pages/UsageDetails/getSortedUsageFeatures'
import RenewalDateIcon from 'src/refactor/assets/icons/calendar-date.svg'
import CalendarIcon from 'src/refactor/assets/icons/calendar.svg'
import ChargeDateIcon from 'src/refactor/assets/icons/charge-date.svg'
import InvoiceIcon from 'src/refactor/assets/icons/invoice.svg'
import ServiceIcon from 'src/refactor/assets/icons/office-card.svg'
import CategoryIcon from 'src/refactor/assets/icons/office-drawer.svg'
import TypeIcon from 'src/refactor/assets/icons/task.svg'
import TravelerIcon from 'src/refactor/assets/people/man.svg'
import { backgroundGray, mainBlack, white } from 'src/refactor/colors'
import { POSCountry } from 'src/travelsuit'
import { Fee, FeesData, ServiceFeeType } from 'src/travelsuit/fees.types'

import {
	CardHeader,
	CardWrapper,
	Country,
	DataWithIconsWrapper,
	PayAgainButton,
	Price,
	PricePart,
	PrimaryInfo,
	Status,
	StyledLink,
} from './FeeCard.components'
import { FeeChipColorMap, getFeeLabel } from './FeeCards.utils'
import { FeeDetail } from './FeeDetail'

interface IProps<T extends FeesData> {
	fee: Fee<T>
	country?: POSCountry | null
	primaryInfo: string
	invoiceProfileName?: string
	secondaryChipText?: string
	onPayAgainClick?: () => void
	traveler?: string
	status?: React.ReactNode
	displayInvoiceProfileNameForSubscription?: boolean
	getFeeDetailsContent?: (defaultContent: React.ReactNode) => React.ReactNode
	statusActions?: React.ReactNode
	additionalPriceDetails?: { label: string; amount: number }[]
}

const BaseFeeCard = <T extends FeesData>({
	fee,
	country,
	invoiceProfileName,
	primaryInfo,
	secondaryChipText,
	onPayAgainClick,
	traveler,
	status,
	displayInvoiceProfileNameForSubscription,
	getFeeDetailsContent = (content) => content,
	statusActions,
	additionalPriceDetails,
}: IProps<T>) => {
	const { t } = useTranslation()
	const feeType = fee.service_type
	const feeData = fee[feeType]
	const {
		created_dt,
		total_price,
		total_tax,
		currency,
		next_payment_date,
		category,
		service,
		type,
		trip,
		charge_dt,
		refund_dt,
	} = feeData

	const feeLabels = getFeeLabel(t)
	const isFree = !total_price
	const isSubscription = [ServiceFeeType.AnnualPayment, ServiceFeeType.MonthlyPayment].includes(feeType)

	return (
		<CardWrapper data-test="FeeCard">
			<CardHeader gap={5} justify="flex-start">
				<Chip
					color={FeeChipColorMap[feeType]}
					textColor={white}
					fontSize={10}
					variant="condensed"
					label={feeLabels[feeType]}
					data-test="Type"
				/>
				{secondaryChipText && (
					<Chip
						color={backgroundGray}
						textColor={mainBlack}
						fontSize={10}
						variant="condensed"
						label={secondaryChipText}
						data-test="SecondaryChip"
					/>
				)}
				{!!country && (
					<>
						<CountryFlag code={country.code} height={16} />
						<Country data-test="Country">{country.name}</Country>
					</>
				)}
				<Status>
					{statusActions}
					{trip?.id && (
						<StyledLink to={routeFor(Routes.Itinerary, { id: trip.id })} data-test="ViewItinerary">
							{t('usage-details.fees.view-itinerary', 'View trip itinerary')}
						</StyledLink>
					)}
					{status}
				</Status>
			</CardHeader>
			<Flex direction="column" align="flex-start" justify="flex-start" gap={10}>
				<PrimaryInfo data-test="Body">{primaryInfo}</PrimaryInfo>
				<DataWithIconsWrapper gap={20} justify="flex-start">
					{getFeeDetailsContent(
						<>
							{created_dt && (
								<FeeDetail
									tooltip={
										isSubscription
											? t('usage-details.start-date', 'Start Date')
											: t('usage-details.fees.fee-date', 'Fee Date')
									}
									iconSrc={CalendarIcon}
									e2e="CreationDate"
									content={formatDate(created_dt)}
								/>
							)}
							{next_payment_date && (
								<FeeDetail
									tooltip={t('usage-details.renewal-date', 'Renewal Date')}
									iconSrc={RenewalDateIcon}
									content={formatDate(next_payment_date)}
									e2e="NextPaymentDate"
								/>
							)}
							{charge_dt && (
								<FeeDetail
									tooltip={t('usage-details.fees.charge-date', 'Charge date')}
									e2e="ChargeDate"
									iconSrc={ChargeDateIcon}
									content={formatDate(charge_dt)}
								/>
							)}
							{refund_dt && (
								<FeeDetail
									tooltip={t('usage-details.fees.refund-date', 'Refund date')}
									e2e="RefundDate"
									iconSrc={RefundDateIcon}
									content={formatDate(refund_dt)}
								/>
							)}
							{invoiceProfileName && (displayInvoiceProfileNameForSubscription || !isSubscription) && (
								<FeeDetail
									tooltip={t('usage-details.fees.invoiced-to-invoice-profile', 'Invoiced To This Invoice Profile')}
									e2e="InvoiceProfile"
									iconSrc={InvoiceIcon}
									content={invoiceProfileName}
								/>
							)}
							{traveler && (
								<FeeDetail
									tooltip={t('usage-details.fees.traveler', 'Traveler')}
									e2e="Traveler"
									iconSrc={TravelerIcon}
									content={traveler}
								/>
							)}
							{category && (
								<FeeDetail
									tooltip={t('usage-details.fees.category', 'Category')}
									e2e="Category"
									iconSrc={CategoryIcon}
									content={category}
								/>
							)}
							{service && (
								<FeeDetail
									tooltip={t('usage-details.fees.service', 'Service')}
									e2e="Service"
									iconSrc={ServiceIcon}
									content={service}
								/>
							)}
							{type && (
								<FeeDetail
									tooltip={t('usage-details.fees.type', 'Type')}
									e2e="FeeType"
									iconSrc={TypeIcon}
									content={type}
								/>
							)}
						</>,
					)}
				</DataWithIconsWrapper>
			</Flex>
			<Price data-test="PriceInfo">
				{isFree ? (
					<span data-test="Free">{t('usage-details.fees.free', 'Free')}</span>
				) : (
					<>
						<PricePart>
							<span>{t('usage-details.fee', 'Fee')}</span>
							<span data-test="Price">{formatPrice(total_price - total_tax, currency)}</span>
						</PricePart>
						<PricePart>
							<span>{t('usage-details.fees.taxes', 'Taxes')}</span>
							<span data-test="Tax">{formatPrice(total_tax, currency)}</span>
						</PricePart>
						<PricePart isTotal>
							<span>{t('usage-details.fees.total-fee', 'Total Fee')}</span>
							<span data-test="Total">{formatPrice(total_price, currency)}</span>
						</PricePart>
						{additionalPriceDetails?.map(({ label, amount }) => (
							<PricePart isTotal key={label}>
								<span>{label}</span>
								<span data-test="Total">{formatPrice(amount, currency)}</span>
							</PricePart>
						))}
					</>
				)}
			</Price>
			{isFeeWithFailedPaymentStatus(feeData) && onPayAgainClick && (
				<PayAgainButton onClick={onPayAgainClick} data-test="PayAgain">
					{t('usage-details.fees.pay-again', 'Pay Again')}
				</PayAgainButton>
			)}
		</CardWrapper>
	)
}

export default BaseFeeCard
