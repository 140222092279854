import { z } from 'zod'

import { TripStatus } from 'src/travelsuit'
import { ServiceFeeType } from 'src/travelsuit/fees.types'
import { WorldlineOrderStatus } from 'src/travelsuit/worldline'

import { CurrencyZ } from './common'
import { UserZ } from './user'

export const FeeIdZ = z.number().brand<'FeeId'>()

export type FeeId = z.infer<typeof FeeIdZ>

const ActiveUserFeeReportDetailZ = z.object({
	user: UserZ.pick({ id: true, first_name: true, last_name: true, middle_name: true }),
	trips: z.array(
		z.object({
			name: z.string(),
			booked_date: z.string(),
			trip_id: z.number(),
			status: z.nativeEnum(TripStatus),
			approved_date: z.string().nullable(),
		}),
	),
	total_price: z.number(),
	currency: CurrencyZ,
	refund_dt: z.string().nullable(),
	status: z.nativeEnum(WorldlineOrderStatus).nullable(),
})

export type ActiveUserFeeReportDetail = z.infer<typeof ActiveUserFeeReportDetailZ>

export const ActiveUserFeeDetailsZ = z.array(ActiveUserFeeReportDetailZ)

export type ActiveUserFeeDetails = z.infer<typeof ActiveUserFeeDetailsZ>

const TripFeeServiceDetailsZ = z.object({
	id: FeeIdZ,
	status: z.nativeEnum(WorldlineOrderStatus).nullable(),
	total_price: z.number(),
})

const VpaFeeServiceDetailsZ = TripFeeServiceDetailsZ.extend({
	bs_info: z.object({
		service_name: z.string(),
		service_dates: z.string(),
	}),
})

const TripFeeZ = z.object({
	service_type: z.literal(ServiceFeeType.Trip),
	[ServiceFeeType.Trip]: TripFeeServiceDetailsZ,
})

const VpaFeeZ = z.object({
	service_type: z.literal(ServiceFeeType.VirtualPaymentAutomation),
	[ServiceFeeType.VirtualPaymentAutomation]: VpaFeeServiceDetailsZ,
})

export const FeeZ = z.union([TripFeeZ, VpaFeeZ])

export type Fee = z.infer<typeof FeeZ>
